import React, { createContext, useContext, useState } from 'react';
import Helmet from 'react-helmet';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';

import { Layout } from '../components/common';
import CityResidential from '../images/icons/2760734-city/svg/034-residential.svg';

const StickyAside = styled.aside`
  ul,
  li {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  @media (min-width: 769px) {
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
  }

  @media (max-width: 766px) {
    text-align: center;
  }
`;

const PaddedBottomWrapper = styled.div`
  @media (min-width: 769px) {
    padding-bottom: 35em;
  }
`;

const MenuContext = createContext({
  current: null,
  setCurrent: () => undefined,
});

function idify(name) {
  return name.toLowerCase().replace(/ /g, '--');
}

const WaypointLink = ({ children, className, href, ...props }) => {
  const { current } = useContext(MenuContext);
  let fullClassName = current === href ? 'is-active' : '';
  if (className) {
    fullClassName += ' ' + className;
  }

  return (
    <a className={fullClassName} href={href} {...props}>
      {children}
    </a>
  );
};

const LifecycleItems = (props) => {
  const { name, items } = props;
  const id = idify(name);

  return (
    <div className="has-mb-5">
      <h2 className="is-size-4 has-mt-0 has-pv-5 has-mb-5 has-text-centered" id={id}>
        {name}
      </h2>
      {items.map(({ name, description, details }) => (
        <LifecycleItem key={idify(name)} name={name} description={description} details={details} />
      ))}
    </div>
  );
};

const LifecycleItem = ({ name, description, details }) => {
  const { current, setCurrent } = useContext(MenuContext);
  const id = idify(name);
  const href = `#${id}`;

  return (
    <Waypoint
      onPositionChange={({ currentPosition }) => {
        if (currentPosition === 'inside') {
          setCurrent(href);
        } else if (current === href) {
          setCurrent(null);
        }
      }}
      bottomOffset="90%"
      fireOnRapidScroll={true}
      key={id}
    >
      <div className="has-mb-5">
        <h3 className="is-size-5 has-mv-0" id={id}>
          {name}
        </h3>
        <p className="content">{description}</p>
        {details ? (
          <ul className="content is-small">
            {details.map((detail) => (
              <li>{detail}</li>
            ))}
          </ul>
        ) : null}
      </div>
    </Waypoint>
  );
};

const LifecyclePage = () => {
  const [current, setCurrent] = useState(null);

  return (
    <MenuContext.Provider value={{ current, setCurrent }}>
      <Layout>
        <Helmet>
          <title>Tenant Lifecycle and Journey</title>
          <meta
            name="description"
            content="All of the parts of the tenant journy including resources and advice to manage them."
          />
        </Helmet>
        <section className="hero is-black">
          <div className="hero-body">
            <div className="container content">
              <div className="columns is-centered is-vcentered">
                <div className="column is-narrow">
                  <figure className="image is-128x128 has-mv-0 is-centered">
                    <img className="is-rounded" src={CityResidential} />
                  </figure>
                </div>
                <div className="column is-5">
                  <h1 className="title is-1 has-text-centered-mobile has-text-left is-marginless">Tenant Journey</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="section is-light">
          <div className="container content">
            <div className="columns is-centered">
              <div className="column is-3">
                <StickyAside className="menu">
                  <p className="menu-label">
                    <WaypointLink href="#prospect">Prospect</WaypointLink>
                  </p>
                  <ul className="menu-list">
                    <li>
                      <WaypointLink href="#passively--search">Passively Search</WaypointLink>
                    </li>
                    <li>
                      <WaypointLink href="#actively--search">Actively Search</WaypointLink>
                    </li>
                  </ul>

                  <p className="menu-label">
                    <WaypointLink href="#lease">Lease</WaypointLink>
                  </p>
                  <ul className="menu-list">
                    <li>
                      <WaypointLink href="#apply">Apply</WaypointLink>
                    </li>
                    <li>
                      <WaypointLink href="#sign--a--lease">Sign a Lease</WaypointLink>
                    </li>
                    <li>
                      <WaypointLink href="#deposit--and--first--months--rent">Deposit / First Months Rent</WaypointLink>
                    </li>
                  </ul>

                  <p className="menu-label">
                    <WaypointLink href="#live">Live</WaypointLink>
                  </p>
                  <ul className="menu-list">
                    <li>
                      <WaypointLink href="#move">Move</WaypointLink>
                    </li>
                    <li>
                      <WaypointLink href="#utilities--and--insurance">Utilities / Insurance</WaypointLink>
                    </li>
                    <li>
                      <WaypointLink href="#disputes">Disputes</WaypointLink>
                    </li>
                  </ul>

                  <p className="menu-label">
                    <WaypointLink href="#leave">Leave</WaypointLink>
                  </p>
                  <ul className="menu-list">
                    <li>
                      <WaypointLink href="#give--notice">Give Notice</WaypointLink>
                    </li>
                    <li>
                      <WaypointLink href="#final--inspection">Final Inspection</WaypointLink>
                    </li>
                    <li>
                      <WaypointLink href="#retrieve--deposit">Retrieve Deposit</WaypointLink>
                    </li>
                  </ul>
                </StickyAside>
              </div>
              <div className="column is-6">
                <PaddedBottomWrapper>
                  <LifecycleItems
                    name="Prospect"
                    items={[
                      {
                        name: 'Passively Search',
                        description:
                          'Research and discover the right placess to live next. The majority of experienced renters consider a number of factors including timing, local trends, crime, and regulation. Without the proper research, you may find yourself looking for a new place to live after your lease ends.',
                        details: [
                          'Put together a checklist of important qualifications for an area.',
                          <span key="research">
                            Use research websites like{' '}
                            <a href="https://padfever.com" title="padfever">
                              Padfever
                            </a>{' '}
                            to map trends and watch markets.
                          </span>,
                          'Learn local state, county, and city roles/regulations in the areas you are looking.',
                          'Try not to spend more than 30% of your gross income on rent (if you can).',
                        ],
                      },
                      {
                        name: 'Actively Search',
                        description: 'Find a place to live that fits your budget and living needs.',
                        details: [
                          'Choose your 3 top areas to live.',
                          <span key="listing">
                            Go to websites like{' '}
                            <a href="https://craigslist.org" title="craigslist">
                              Craigslist
                            </a>{' '}
                            to find places to live.
                          </span>,
                          'Visit at least 5 places to build your bearings.',
                          <span key="inspect">
                            <a href="https://padfever.com/features/inspect" title="padfever inspect">
                              Inspect
                            </a>{' '}
                            the property manager and building to uncover unknown risks like{' '}
                            <a href="https://youtu.be/MPFPBzr7FgY?t=599" key="renoviction">
                              renoviction
                            </a>
                          </span>,
                        ],
                      },
                    ]}
                  />

                  <LifecycleItems
                    name="Lease"
                    items={[
                      {
                        name: 'Apply',
                        description:
                          'Most property managers ask prospective tenants to fill out an application. The application usually requests some basic information and evidence such as proof of income, a credit report, and prior renting references.',
                        details: [
                          'Be wary of scams.',
                          'Be wary of high application fees in high demand areas (San Francisco, New York, Los Angeles, etc.). Look for maximum application fee amounts in local regulations.',
                          'It is normal for a property manager to ask for current and prior rent with contact information. They will check all references given to them.',
                          'The most common form of proof of income is 2 paystubs.',
                          'If an application requests your social security number, make sure it is electronic and tells you how it will be used.',
                          <span key="soft-inquiry">
                            Do your best to avoid letting a property manager run credit checks unless they guarantee it
                            is a{' '}
                            <a
                              href="https://homeguides.sfgate.com/apartment-credit-checks-lower-score-49750.html"
                              title="credit check inquiry impact"
                            >
                              soft inquiry
                            </a>
                            . They can hurt your credit score which is{' '}
                            <a
                              href="https://www.consumer.ftc.gov/blog/2016/09/why-your-credit-score-matters"
                              title="credit score matters"
                            >
                              not a good thing
                            </a>
                            .
                          </span>,
                        ],
                      },
                      {
                        name: 'Sign a Lease',
                        description:
                          'Get ready to read a lengthy legal document. Know your rights in your state, county, and city to understand what is enforceable and what is not.',
                        details: [
                          'Be wary of hidden fees, responsibilities, maintenance requirements, start date, end date, and lease termination clauses.',
                          'Read through every section thoroughly and check against local laws. Have a full understanding of what is enforceable and what is not.',
                          'If the property manage would like a holding deposit, make sure there is an agreement in place to get some or all of it back.',
                        ],
                      },
                      {
                        name: 'Deposit And First Months Rent',
                        description:
                          'Your landlord will probably ask for a security deposit and first months rent in advance. The deposit is typically the size of one or two months rent and is used as insurance against broken leases and physical damages. You should be able to get the majority of this back at the end of your lease when you move out.',
                        details: [
                          'Be wary of property managers with a history of deposit theft.',
                          'Use cashiers checks initially to build trust with your landlord.',
                        ],
                      },
                    ]}
                  />

                  <LifecycleItems
                    name="Live"
                    items={[
                      {
                        name: 'Move',
                        description:
                          'Pack your stuff up and move. This can be an incredibly stressful thing to do. Damaging your own and your former rentals property is very common.',
                        details: [
                          'Some professional moving services are insured. A great option when in fear of property damages.',
                          'Moving yourself can be easier if you have the proper equipment. Try renting a truck and dolly.',
                        ],
                      },
                      {
                        name: 'Utilities And Insurance',
                        description:
                          'The first order of business when beginning your life in a new apartment or house is to setup utilies and get the proper insurances. Most of these utilities (electricity, water, trash, and internet) are paid on a monthly, bi-monthly, or quarterly basis. Usually water and trash will be shared across many apartments. Your landlord may include them in your rent or ask you to pay through them. It is important to know how much you are using to make sure utilities are split equitably.',
                        details: ['Renters insurance will guarantee what you own.'],
                      },
                      {
                        name: 'Disputes',
                        description:
                          'Every living situation has its setbacks and disputes. Whether it be with your landlord or a neighbor, be prepared to talk things out. Make sure to communicate over email and never commit to any thing until you fully understand who is responsible.',
                        details: [
                          'Know local laws and regulations. It will help resolve disputes before they start.',
                          'Local tenant rights advocacy groups are helpful at resolving all kinds of tought disputes.',
                        ],
                      },
                    ]}
                  />

                  <LifecycleItems
                    name="Leave"
                    items={[
                      {
                        name: 'Give Notice',
                        description:
                          'It is customary to give a 30-days notice before you leave a unit. Your landlord will likely show prospective tenants your unit.',
                      },
                      {
                        name: 'Final Inspection',
                        description:
                          'At the end of your lease, the property will be inspected for damages. Any thing that is not normal wear and tear can be deducted against your security deposit.',
                      },
                      {
                        name: 'Retrieve Deposit',
                        description:
                          'Once the inspection has been completed, you should get back most of your security deposit back. Sometimes landlords will charge you for cleaning the property as well. It is important to request a ledger from your landlord to fully understand the costs deducted from your security deposit.',
                        details: [
                          'Many state laws and some local ordinances mandate a timely return of the deposit',
                          'In some states (California in particular), a ledger describing what has been deducted is required by law.',
                          'The deposit is not supposed to be used for wear and tear.',
                          'The deposit can be used to clean the unit.',
                        ],
                      },
                    ]}
                  />
                </PaddedBottomWrapper>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </MenuContext.Provider>
  );
};

export default LifecyclePage;
